import img1 from './0.png';
import img2 from './1.png';

import './App.css';

function App() {
  return (
    <div className="App">
      <div>
        <img className='fit-img' src={img1} alt='img1' />
        <img className='fit-img' src={img2} alt='img2' />
      </div>
    </div>
  );
}

export default App;
